<template>
  <div :style="cssProps">
    <div
      v-if="
        $router.currentRoute.name == 'home-calendar-widget' ||
        $router.currentRoute.name == null
      "
    >
      <Calendar
        :is_button="is_button"
        :meeting_type="meeting_type"
        :is_flotating="is_flotating"
        :customer_id="customer_id"
        :project_id="project_id"
        :country="country"
        :top="top"
        :bottom="bottom"
        :left="left"
        :right="right"
        :color="color"
        :color_text="color_text"
        :source="source"
        :show_always_text="show_always_text"
        :no_dni="no_dni"
        :is_virtual="is_virtual"
      />
    </div>
    <div v-else id="app">
      <router-view />
    </div>
  </div>
</template>

<script>
import Calendar from "./components/Calendar.vue";
export default {
  name: "App",
  props: {
    meeting_type: { default: false },
    is_button: { default: true },
    is_flotating: { default: true },
    customer_id: { default: null },
    project_id: { default: null },
    country: { default: "CL" },
    top: { default: null },
    bottom: { default: "0px" },
    left: { default: "0px" },
    right: { default: null },
    color: { default: "#f14a4a" },
    color_text: { default: "#FFFFFF" },
    show_always_text: { default: false },
    source: { default: null },
    no_dni: { default: false },
    is_virtual: { default: true },
  },
  components: {
    Calendar,
  },
  computed: {
    cssProps() {
      return {
        "--color": this.color,
        "--color-text": this.color_text,
      };
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "assets/sass/app";
</style>
