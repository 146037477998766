<template>
	<div v-if="displayed" class="container">
		<div class="card">
			<div class="card-body">
				<div class="text-center" style="margin-top: 30px">
					<img :src="this.customer.logo_url" width="300px" />
				</div>
				<div>
					<h4 class="text-center"><p>¿Estimado {{this.appointment.executive.name}}, estás seguro de que deseas rechazar esta reunión?</p></h4>
					<div v-if="this.appointment.have_last_executive.response" class="h5 py-3 text-center alert alert-warning" show>¡Aténción eres el último ejecutivo disponible para esta reunión!</div>
					<div class="text-right pt-3">
						<label>Razón</label>
						<select class="form-control w-100" v-model="form.reject_types_id" text-field="name" value-field="id">
							<option disabled>---Seleccionar---</option>
							<option v-for="option in reject_types" v-bind:value="option.id" v-bind:key="'reject_'+option.id">
								{{ option.name }}
							</option>
						</select>
					</div>
					<div>
						<label>Detalle</label>
						<input type="text" class="form-control" v-model="form.text" placeholder="Razón de rechazo" />
					</div>
					<div class="text-right pt-3">
						<div v-if="loading">
							<center><grid-loader :color="color"></grid-loader></center>
						</div>
						<button class="btn btn-danger btn-block" @click="save()">Guardar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="card">
			<div class="card-body">
				<div class="text-center">
					<img :src="this.customer.logo_url" width="300px" />
				</div>
				<div>
					<h4 class="text-center"><p>Cita ya Gestionada</p></h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppointmentMixin from "../../mixins/appointment"
export default {
	name: 'rejecting',
	mixins: [AppointmentMixin],
	props:{
		token: {default: null}
	},
	data(){
		return {
			reject_types: [],
			form: {text: null, reject_types_id: null},
			is_last_executive: false
		}
	},
	beforeMount(){
		this.get_rejected_types()
	},
	methods: {
		save(){
			this.loading = true
			this.axios.post("/api/v1/appointments/reject", {token: this.token, reject: this.form}).then(result => {
				let text = this.appointment.have_last_executive.response ? "rechazado y el cliente se ha notificado" : "derivado a otro ejecutivo"
				this.$swal({icon: 'success', title: 'Rechazado', text: 'La cita se ha '+text, showConfirmButton: false});
				console.log(result)
				this.displayed = false
				this.loading = false
			}).catch(error => {
				this.loading = false
				this.$swal({icon: 'error', title: 'Oops...', text: '¡Algo anda mal!, favor comunicate con soporte'});
				console.log(error)
			})
		},
		get_rejected_types(){
			this.axios.get("/api/v1/appointments/reject_types").then(result => {
				this.reject_types = result.data
			}).catch(error => {
				console.log(error)
			})
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
