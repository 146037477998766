<template>
	<div v-if="displayed" class="container">
		<div class="card">
			<div class="card-body">
				<div class="text-center" style="margin-top: 30px">
					<img :src="this.customer.logo_url" width="300px" />
				</div>
				<div>
					<h4 class="text-center"><p>¿Estimado {{this.appointment.executive.name}}, estás seguro de que deseas aceptar esta reunión?</p></h4>
					<div class="text-right pt-3">
						<div v-if="loading">
							<center><grid-loader :color="color"></grid-loader></center>
						</div>
						<button @click="save()" type="button" class="btn btn-success btn-block">Aceptar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="card">
			<div class="card-body">
				<div class="text-center">
					<img :src="this.customer.logo_url" width="300px" />
				</div>
				<div>
					<h4 class="text-center"><p>Cita ya Gestionada</p></h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppointmentMixin from "../../mixins/appointment"
export default {
    name: 'acepting',
    mixins: [AppointmentMixin],
    props:{
        token: {default: null}
	},
	methods: {
        save(){
			this.loading = true
            this.axios.post("/api/v1/appointments/confirm", {token: this.token}).then(result => {
				this.$swal({icon: 'success', title: 'Confirmado', text: '¡La cita ha sido confirmada y notificada al cliente!', showConfirmButton: false});
				console.log(result)
				this.loading = false
				this.displayed = false
            }).catch(error => {
				this.loading = false
				this.$swal({icon: 'error', title: 'Oops...', text: '¡Algo anda mal!, favor comunicate con soporte'});
                console.log(error)
            })
        }
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
