export default  {
    data(){
        return {
            appointment: { name: null, executive: {name: null}},
            customer: { name: null },
            displayed: true,
            loading: false,
            color: "#00789e"
        }
    },
	beforeMount(){
        this.set_appointment()
	},
    methods: {
        set_appointment(){
            this.axios.get("/api/v1/appointments/"+this.token).then(result => {
                this.appointment = result.data.appointment
                this.customer = result.data.customer
                this.displayed = result.data.appointment.status == "unconfirmed" ? true : false
            }).catch(error => {
                console.log(error)
            })
        },
    }
}
