<template>
	<div class="bg-calendar-page"  :style="cssProps">
        <div class="bg-calendar-page-inner">
            <div class="bg-calendar-img-container">
                <img class="bg-calendar-img" v-if="logo" :src="logo" :alt="customer_id" />
            </div>
            <h2>Agenda tu reunión con <b v-if="projectData">{{projectData.nombreProyecto}}</b></h2>
            <h3>Selecciona una <b>fecha y hora</b> para juntarnos</h3>
            <div class="bg-calendar-page-container">
                <Calendar :is_button="is_button" :meeting_type="meeting_type" :is_flotating="is_flotating" :customer_id="customer_id" :project_id="project_id" :country="country" :top="top" :bottom="bottom" :left="left" :right="right" :color="color" :color_text="color_text" :source="source" :show_always_text="show_always_text" :no_dni="no_dni" :executives="executives" />
            </div>
        </div>
	</div>
</template>
<script>
    import Calendar from '../../../components/Calendar.vue';
    export default {
        name: 'CalendarPage',
        props: {
            meeting_type: { default: false },
            is_button: {default: false},
            is_flotating: {default: true},
            customer_id: {default: null },
            project_id: {default: null },
            country: {  default: 'CL' },
            top: {  default: null },
            bottom: {  default: '0px' },
            left: {  default: '0px' },
            right: {  default: null },
            color: {default: '#5db5b0'},
            color_text: {default: '#FFFFFF'},
            show_always_text: {default: false},
            source: {default: null},
            no_dni: {default: false},
            executives: {default: null},

        },
        data(){
            return {
                logo: null,
                projectData: null
            }
        },
        components: {
            Calendar
        },
        mounted() {
            console.log('CalendarPage mounted');
            let self = this;
            this.axios.get('https://cotizacion.mobysuite.com/api/v2/quotes/list-projects', {
                params: {
                    real_estate: this.customer_id,
                },
            })
            .then(response => {
                self.projectData = response.data.find(
					project => project['id'] == self.project_id
				)
                self.logo = self.projectData.urlLogoProyecto ? `https://s3.amazonaws.com/gestion.mobysuite/${self.projectData.urlLogoProyecto}` : null;
            })
        },
        computed: {
            cssProps() {
                return {
                    '--color': this.color,
                    '--color-text': this.color_text,
                }
            }
        }
    }
</script>
<style>
    html, body {
        margin: 0;
        padding: 0;
    }
</style>
<style lang="scss" scoped>
    .bg-calendar-page {
        margin: 0;
        padding: 0;
        background-color: #1f4d58;
        min-height: 100vh;
        .bg-calendar-page-inner{
            max-width: 800px;
            margin: 0 auto;
        }
        .bg-calendar-page-container{
            background-color: #FFF;
            padding: 30px;
            border-radius: 15px;
        }
        h2,h3 {
            font-weight: 300;
            color: #FFF;
            font-family: Helvetica, sans-serif;
            text-align: center;
        }
        .bg-calendar-img-container {
            padding-top: 100px;
            text-align: center;
        }
        .bg-calendar-img {
            max-width: 250px;
            max-height: 150px;
        }
    }
</style>
