
import Home from './pages/home.vue';

export const PublicRoutes =
  [
    {
      path: '',
      component: Home,
      props: true,
      name: 'home-calendar-widget',
    },
    {
      path: '/:customer_id/proyecto/:project_id',
      component: Home,
      props: true,
      name: 'home-calendar-inner',
    },
  ];
