
import MeetingAcepting from './pages/meeting/acepting.vue';
import MeetingRejecting from './pages/meeting/rejecting.vue';
import CalendarPage from './pages/calendarPage.vue';

export const PrivateRoutes =
  [

    {
      path: '/acepting/:token',
      component: MeetingAcepting,
      props: true,
      name: 'meeting-acepting',
    },
    {
      path: '/rejecting/:token',
      component: MeetingRejecting,
      props: true,
      name: 'meeting-rejecting',
    },
    {
        path: '/calendar/:customer_id/project/:project_id/:meeting_type?',
        component: CalendarPage,
        props: true,
        name: 'calendar-page',
    },
  ];
