<template>
  <div>
    <div v-if="is_button">
      <div v-if="is_flotating">
        <button
          type="button"
          :class="{
            'btn btn-show-calendar btn-show-calendar-flotating btn-calendar': true,
            'btn-close': !hideCalendar,
            'show-always-text': show_always_text,
          }"
          :style="{
            top: style.top,
            bottom: style.bottom,
            left: style.left,
            right: style.right,
          }"
          @click="setElements()"
        >
          <template v-if="hideCalendar">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="calendar-alt"
              class="svg-inline--fa fa-calendar-alt fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <g class="fa-group">
                <path
                  class="fa-secondary"
                  :fill="color_text"
                  d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm128 244a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-80-180h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                  opacity="0.4"
                ></path>
                <path
                  class="fa-primary"
                  :fill="color_text"
                  d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
                ></path>
              </g>
            </svg>
          </template>
          <template v-else>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="times"
              class="svg-inline--fa fa-times fa-w-11 times-button"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 352 512"
            >
              <g class="fa-group">
                <path
                  class="fa-secondary"
                  :fill="color_text"
                  d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                  opacity="0.4"
                ></path>
                <path
                  class="fa-primary"
                  :fill="color_text"
                  d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"
                ></path>
              </g>
            </svg>
          </template>
          <span id="btn-calendar-text" class="btn-calendar-text"
            >Juntémonos</span
          >
        </button>
      </div>
      <button
        v-else
        type="button"
        :class="{
          'btn btn-show-calendar btn-calendar': true,
          'btn-close': !hideCalendar,
        }"
        @click="setElements()"
      >
        <template v-if="hideCalendar">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="calendar-alt"
            class="svg-inline--fa fa-calendar-alt fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <g class="fa-group">
              <path
                class="fa-secondary"
                :fill="color_text"
                d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm128 244a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-80-180h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                opacity="0.4"
              ></path>
              <path
                class="fa-primary"
                :fill="color_text"
                d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
              ></path>
            </g>
          </svg>
        </template>
        <template v-else>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11 times-button"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <g class="fa-group">
              <path
                class="fa-secondary"
                :fill="color_text"
                d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                opacity="0.4"
              ></path>
              <path
                class="fa-primary"
                :fill="color_text"
                d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"
              ></path>
            </g>
          </svg>
        </template>
      </button>
      <transition name="modal" v-if="showModal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            @click="(showModal = !showModal), closeModalEvent()"
          >
            <div class="modal-container" @click.stop>
              <div class="modal-content">
                <div class="modal-header">
                  <slot name="header">
                    <button
                      type="button"
                      aria-label="Close"
                      @click="(showModal = !showModal), closeModalEvent()"
                      class="close"
                    >
                      ×
                    </button>
                    <h5 class="modal-title text-center w-100">
                      {{ modalTitle }}
                    </h5>
                  </slot>
                </div>
                <div class="modal-body my-0">
                  <slot name="body">
                    <div v-if="showEventType" class="row">
                      <div class="col-sm-11 pl-5" align="center">
                        <div class="wrapper">
                          <input
                            type="radio"
                            id="presencial"
                            value="VISITA"
                            v-model="appoint.event_type"
                            @click="goCalendarV"
                          />
                          <input
                            type="radio"
                            id="conferencia"
                            value="VIDEO CONFERENCIA"
                            v-model="appoint.event_type"
                            @click="goCalendarVC"
                          />
                          <label for="presencial" class="option option-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-house"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                              />
                            </svg>
                            <span> Visita presencial </span>
                          </label>
                          <br />
                          <label for="conferencia" class="option option-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-camera-video"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                              />
                            </svg>
                            <span> Video Conferencia </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="!showForm" class="row">
                        <div class="col-sm-5 px-0">
                          <div
                            v-if="calendar_get_dates_loading"
                            cols="12"
                            class="text-center mt-5 mb-5"
                          >
                            <grid-loader class="mx-auto"></grid-loader>
                          </div>
                          <datepicker
                            v-else
                            class="w-100 mb-3 px-2"
                            v-model="reservation.date"
                            bootstrap-styling
                            :disabled-dates="dateState.disabled"
                            :highlighted="dateState.highlighted"
                            :monday-first="true"
                            :language="es"
                            :inline="true"
                            :key="dateState.disabled.dates.length"
                            :full-month-name="true"
                          ></datepicker>
                          <div align="center" v-if="showMessage">
                            <span class="show-event-type">
                              <div class="icon-event">
                                <svg
                                  v-if="appoint.event_type == 'VISITA'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="17"
                                  fill="currentColor"
                                  class="bi bi-house"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                                  />
                                </svg>
                                <svg
                                  v-if="
                                    appoint.event_type == 'VIDEO CONFERENCIA'
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  class="bi bi-camera-video"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                                  />
                                </svg>
                              </div>
                              {{ appoint.event_type }}
                            </span>
                            <br />
                            <a
                              href="javascript:;"
                              @click="setEventType()"
                              class="small text-grey"
                              >CAMBIAR TIPO DE REUNION</a
                            >
                          </div>
                        </div>
                        <div class="col-sm-7 px-0">
                          <div class="hours-list px-2">
                            <div
                              class="w-100"
                              v-if="calendar_get_hours_loading"
                            >
                              <center>
                                <grid-loader
                                  color="#CCCCCC"
                                  class="mx-auto"
                                ></grid-loader>
                              </center>
                            </div>
                            <div
                              class="mx-auto px-2"
                              v-else-if="hours.length > 0"
                            >
                              <h4 class="text-center mt-0">
                                Hora(s) disponible(s)
                              </h4>
                              <template v-for="(hour, index) in hours">
                                <span
                                  class="pills"
                                  @click="selectHour(hour)"
                                  :key="'hour' + index"
                                  v-if="hour.seats >= 1"
                                >
                                  <b>{{
                                    hour.hour.to | moment("utc", "HH:mm")
                                  }}</b>
                                  <span class="pills-check">
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fad"
                                      data-icon="check"
                                      class="svg-inline--fa fa-check fa-w-16"
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <g class="fa-group">
                                        <path
                                          class="fa-secondary"
                                          fill="#009944"
                                          d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                                          opacity="0.4"
                                        ></path>
                                        <path
                                          class="fa-primary"
                                          fill="#009944"
                                          d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                                        ></path>
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                              </template>
                            </div>
                            <div v-else-if="hours.length == 0">
                              <h3 class="text-center mt-0">
                                No hay horas disponibles para el día de hoy.
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="row">
                        <div class="col-md-4 col-sm-5 px-0">
                          <div class="text-center cal-container">
                            <div
                              class="card m-4 text-center w-75 mx-auto"
                              v-if="reservation.date && selectedHour"
                            >
                              <div class="card-header bg-calendar">
                                <b>{{ reservation.date | moment("dddd") }}</b>
                              </div>
                              <div class="card-body">
                                <h1>{{ reservation.date | moment("D") }}</h1>
                                {{ reservation.date | moment("MMMM") }}
                              </div>
                              <div class="card-footer bg-calendar">
                                <b>{{
                                  selectedHour.to | moment("utc", "HH:mm")
                                }}</b>
                              </div>
                            </div>
                            <div align="center" v-if="showMessage">
                              <span class="show-event-type">
                                <div class="icon-event">
                                  <svg
                                    v-if="appoint.event_type == 'VISITA'"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="17"
                                    fill="currentColor"
                                    class="bi bi-house"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                                    />
                                  </svg>
                                  <svg
                                    v-if="
                                      appoint.event_type == 'VIDEO CONFERENCIA'
                                    "
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    class="bi bi-camera-video"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                                    />
                                  </svg>
                                </div>
                                {{ appoint.event_type }}
                              </span>
                              <br />
                              <a
                                href="javascript:;"
                                @click="goBack()"
                                class="small text-grey"
                                >CAMBIAR HORA</a
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8 col-sm-7 px-0">
                          <div class="px-2">
                            <template v-if="country == 'CL' && no_dni == false">
                              <div class="form-group">
                                <label>{{ rut }}</label>
                                <input
                                  type="text"
                                  v-model.trim="$v.appoint.rut.$model"
                                  :state="
                                    $v.appoint.rut.$dirty
                                      ? !$v.appoint.rut.$error
                                      : null
                                  "
                                  class="form-control input-sm"
                                />
                                <div
                                  class="text-sm text-red"
                                  v-if="$v.appoint.rut.$error"
                                >
                                  <div class="error">
                                    <small>* {{ rut }} es requerido</small>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div class="form-group">
                              <label>Nombre</label>
                              <input
                                type="text"
                                v-model="appoint.name"
                                class="form-control input-sm"
                              />
                              <div
                                class="text-sm text-red"
                                v-if="$v.appoint.name.$error"
                              >
                                <div class="error">
                                  <small>* Nombre es requerido</small>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Apellido</label>
                              <input
                                type="text"
                                v-model="appoint.lastname"
                                class="form-control input-sm"
                              />
                              <div
                                class="text-sm text-red"
                                v-if="$v.appoint.lastname.$error"
                              >
                                <div class="error">
                                  <small>* Apellido es requerido </small>
                                </div>
                              </div>
                            </div>
                            <template v-if="country == 'PE' && no_dni == false">
                              <div class="form-group">
                                <label>{{ rut }}</label>
                                <input
                                  type="text"
                                  v-model.trim="$v.appoint.rut.$model"
                                  :state="
                                    $v.appoint.rut.$dirty
                                      ? !$v.appoint.rut.$error
                                      : null
                                  "
                                  class="form-control input-sm"
                                />
                                <div
                                  class="text-sm text-red"
                                  v-if="$v.appoint.rut.$error"
                                >
                                  <div class="error">
                                    <small>* {{ rut }} es requerido</small>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div class="form-group">
                              <label>Celular</label>
                              <div class="row mx-0">
                                <div class="col-xs-2 px-0">
                                  <select
                                    class="form-control input-sm mx-0 w-100 brd-0 select-sm"
                                    v-model="phoneCode"
                                    @change="checkPhone"
                                  >
                                    <option
                                      v-for="(code, key) in filteredCode"
                                      :key="'selCountry' + key"
                                      :value="code.dialCode"
                                    >
                                      +{{ code.dialCode }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-xs-10 px-0">
                                  <input
                                    type="text"
                                    v-model="phone"
                                    @input="checkPhone"
                                    class="form-control input-sm mx-0 bld-0"
                                  />
                                </div>
                              </div>
                              <div
                                class="text-sm text-red"
                                v-if="$v.appoint.telphone.$error"
                              >
                                <div class="error">
                                  <small>* Teléfono es requerido</small>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                v-model="appoint.email"
                                class="form-control input-sm"
                              />
                              <div
                                class="text-sm text-red"
                                v-if="$v.appoint.email.$error"
                              >
                                <div class="error">
                                  <small>* Email es requerido</small>
                                </div>
                              </div>
                            </div>
                            <div v-if="loading">
                              <center>
                                <grid-loader :color="color"></grid-loader>
                              </center>
                            </div>
                            <div v-else class="form-group text-center">
                              <p
                                href="javascript:;"
                                class="btn text-center justify-center btn-success"
                                @click="send"
                              >
                                AGENDAR
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>
      <div v-if="showEventType" class="row">
        <div class="col-sm-11 pl-5" align="center">
          <div class="wrapper">
            <input
              type="radio"
              id="presencial"
              value="VISITA"
              v-model="appoint.event_type"
              @click="goCalendarV"
            />
            <input
              type="radio"
              id="conferencia"
              value="VIDEO CONFERENCIA"
              v-model="appoint.event_type"
              @click="goCalendarVC"
            />
            <label for="presencial" class="option option-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-house"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                />
                <path
                  fill-rule="evenodd"
                  d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                />
              </svg>
              <span> Visita presencial </span>
            </label>
            <br />
            <label for="conferencia" class="option option-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-camera-video"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
              <span> Video Conferencia </span>
            </label>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!showForm" class="row">
          <div class="col-sm-5 px-0">
            <div
              v-if="calendar_get_dates_loading"
              cols="12"
              class="text-center mt-5 mb-5"
            >
              <grid-loader class="mx-auto"></grid-loader>
            </div>
            <datepicker
              v-else
              class="w-100 mb-3 px-2"
              v-model="reservation.date"
              bootstrap-styling
              :disabled-dates="dateState.disabled"
              :highlighted="dateState.highlighted"
              :monday-first="true"
              :language="es"
              :inline="true"
              :key="dateState.disabled.dates.length"
              :full-month-name="true"
            ></datepicker>
            <div align="" v-if="showMessage">
              <span class="show-event-type">
                <div class="icon-event">
                  <svg
                    v-if="appoint.event_type == 'VISITA'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    class="bi bi-house"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                    />
                  </svg>
                  <svg
                    v-if="appoint.event_type == 'VIDEO CONFERENCIA'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    class="bi bi-camera-video"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                    />
                  </svg>
                </div>
                {{ appoint.event_type }}
              </span>
              <br />
              <a
                href="javascript:;"
                @click="setEventType()"
                class="small text-grey"
                >CAMBIAR TIPO DE REUNION</a
              >
            </div>
          </div>
          <div class="col-sm-7 px-0">
            <div class="hours-list px-2">
              <div class="w-100" v-if="calendar_get_hours_loading">
                <center>
                  <grid-loader color="#CCCCCC" class="mx-auto"></grid-loader>
                </center>
              </div>
              <div class="mx-auto px-2" v-else-if="hours.length > 0">
                <h4 class="text-center mt-0">Hora(s) disponible(s)</h4>
                <template v-for="(hour, index) in hours">
                  <span
                    class="pills"
                    @click="selectHour(hour)"
                    :key="'hour' + index"
                    v-if="hour.seats >= 1"
                  >
                    <b>{{ hour.hour.to }}</b>
                    <span class="pills-check">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="check"
                        class="svg-inline--fa fa-check fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <g class="fa-group">
                          <path
                            class="fa-secondary"
                            fill="#009944"
                            d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                            opacity="0.4"
                          ></path>
                          <path
                            class="fa-primary"
                            fill="#009944"
                            d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </span>
                </template>
              </div>
              <div v-else-if="hours.length == 0">
                <h3 class="text-center mt-0">
                  No hay horas disponibles para el día de hoy.
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-4 col-sm-5 px-0">
            <div class="text-center cal-container">
              <div
                class="card m-4 text-center w-75 mx-auto"
                v-if="reservation.date && selectedHour"
              >
                <div class="card-header bg-calendar">
                  <b>{{ reservation.date | moment("dddd") }}</b>
                </div>
                <div class="card-body">
                  <h1>{{ reservation.date | moment("D") }}</h1>
                  {{ reservation.date | moment("MMMM") }}
                </div>
                <div class="card-footer bg-calendar">
                  <b>{{ selectedHour.to | moment("utc", "HH:mm") }}</b>
                </div>
              </div>
              <div align="center" v-if="showMessage">
                <span class="show-event-type">
                  <div class="icon-event">
                    <svg
                      v-if="appoint.event_type == 'VISITA'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="currentColor"
                      class="bi bi-house"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                      />
                    </svg>
                    <svg
                      v-if="appoint.event_type == 'VIDEO CONFERENCIA'"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-camera-video"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                      />
                    </svg>
                  </div>
                  {{ appoint.event_type }}
                </span>
              </div>
              <br />
              <a href="javascript:;" @click="goBack()" class="small text-grey"
                >CAMBIAR HORA</a
              >
            </div>
          </div>
          <div class="col-md-8 col-sm-7 px-0">
            <div class="px-2">
              <template v-if="country == 'CL' && no_dni == false">
                <div class="form-group">
                  <label>Rut</label>
                  <input
                    type="text"
                    v-model.trim="$v.appoint.rut.$model"
                    :state="
                      $v.appoint.rut.$dirty ? !$v.appoint.rut.$error : null
                    "
                    class="form-control input-sm"
                  />
                  <div class="text-sm text-red" v-if="$v.appoint.rut.$error">
                    <div class="error"><small>* Rut es requerido</small></div>
                  </div>
                </div>
              </template>
              <div class="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  v-model="appoint.name"
                  class="form-control input-sm"
                />
                <div class="text-sm text-red" v-if="$v.appoint.name.$error">
                  <div class="error"><small>* Nombre es requerido</small></div>
                </div>
              </div>
              <div class="form-group">
                <label>Apellido</label>
                <input
                  type="text"
                  v-model="appoint.lastname"
                  class="form-control input-sm"
                />
                <div class="text-sm text-red" v-if="$v.appoint.lastname.$error">
                  <div class="error">
                    <small>* Apellido es requerido </small>
                  </div>
                </div>
              </div>
              <template v-if="country == 'PE' && no_dni == false">
                <div class="form-group">
                  <label>DNI</label>
                  <input
                    type="text"
                    v-model.trim="$v.appoint.rut.$model"
                    :state="
                      $v.appoint.rut.$dirty ? !$v.appoint.rut.$error : null
                    "
                    class="form-control input-sm"
                  />
                  <div class="text-sm text-red" v-if="$v.appoint.rut.$error">
                    <div class="error"><small>* DNI es requerido</small></div>
                  </div>
                </div>
              </template>
              <div class="form-group">
                <label>Celular</label>
                <div class="row mx-0">
                  <div class="col-xs-2 px-0">
                    <select
                      class="form-control select-sm input-sm mx-0 w-100 brd-0"
                      v-model="phoneCode"
                      @change="checkPhone"
                    >
                      <option
                        v-for="(code, key) in filteredCode"
                        :key="'selCountry' + key"
                        :value="code.dialCode"
                      >
                        +{{ code.dialCode }}
                      </option>
                    </select>
                  </div>
                  <div class="col-xs-10 px-0">
                    <input
                      type="text"
                      v-model="phone"
                      @input="checkPhone"
                      class="form-control input-sm mx-0 bld-0"
                    />
                  </div>
                </div>
                <div class="text-sm text-red" v-if="$v.appoint.telphone.$error">
                  <div class="error">
                    <small>* Teléfono es requerido</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  v-model="appoint.email"
                  class="form-control input-sm"
                />
                <div class="text-sm text-red" v-if="$v.appoint.email.$error">
                  <div class="error"><small>* Email es requerido</small></div>
                </div>
              </div>
              <div v-if="loading">
                <center><grid-loader :color="color"></grid-loader></center>
              </div>
              <div v-else class="form-group text-center">
                <p
                  href="javascript:;"
                  class="btn text-center justify-center btn-success"
                  @click="send"
                >
                  AGENDAR
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
const moment = require("moment");
import { required, minLength, email } from "vuelidate/lib/validators";
import { isRutValid } from "@/validators";

export default {
  name: "Calendar",
  components: {
    Datepicker,
  },
  props: {
    meeting_type: { default: false },
    is_button: { default: true },
    is_flotating: { default: true },
    customer_id: { default: null },
    project_id: { default: null },
    country: { default: "CL" },
    top: { default: null },
    bottom: { default: "0px" },
    left: { default: "0px" },
    right: { default: null },
    color: { default: "#f14a4a" },
    color_text: { default: "#FFFFFF" },
    show_always_text: { default: false },
    source: { default: null },
    no_dni: { default: false },
    is_virtual: { default: true },
  },
  validations() {
    if (this.country == "CL") {
      if (this.no_dni == false) {
        return {
          appoint: {
            rut: { required, RutValid: isRutValid },
            name: { required, minLength: minLength(3) },
            lastname: { required, minLength: minLength(3) },
            telphone: { required, minLength: minLength(9) },
            email: { required, email },
          },
        };
      } else {
        return {
          appoint: {
            name: { required, minLength: minLength(3) },
            lastname: { required, minLength: minLength(3) },
            telphone: { required, minLength: minLength(9) },
            email: { required, email },
          },
        };
      }
    } else {
      if (this.no_dni == false) {
        return {
          appoint: {
            rut: { required },
            name: { required, minLength: minLength(3) },
            lastname: { required, minLength: minLength(3) },
            telphone: { required, minLength: minLength(9) },
            email: { required, email },
          },
        };
      } else {
        return {
          appoint: {
            name: { required, minLength: minLength(3) },
            lastname: { required, minLength: minLength(3) },
            telphone: { required, minLength: minLength(9) },
            email: { required, email },
          },
        };
      }
    }
  },
  data() {
    return {
      style: {
        top: null,
        bottom: null,
        left: null,
        right: null,
      },
      showModal: false,
      showEventType: false,
      showMessage: false,
      modalTitle:
        "Selecciona una fecha y hora para juntarte con uno de nuestros ejecutivos",
      parsedCountry: this.country.split(","),
      branches: [],
      hourTitle: null,
      send_loading: false,
      calendar_get_dates_loading: false,
      calendar_reservation_loading: false,
      calendar_get_hours_loading: false,
      hideCalendar: true,
      showForm: false,
      loading: false,
      footerText: "ACEPTAR",
      footerClass: "btn-info",
      es: es,
      rut: this.country == "CL" ? "Rut" : "DNI",
      calendarData: {},
      errors: "",
      hour: "",
      selectedHour: { from: new Date(), to: new Date() },
      date: "",
      years: [],
      appoint: {
        rut: null,
        name: null,
        lastname: null,
        telphone: null,
        space_id: null,
        appointment_date: null,
        project: this.project_id,
        source: this.source,
        event_type: this.is_virtual ? "VIDEO CONFERENCIA" : "VISITA",
      },
      phone: null,
      phoneCode: null,
      dateState: {
        disabled: {
          to: new Date(new Date().setDate(new Date().getDate() - 1)),
          dates: [],
        },
        highlighted: {
          dates: [new Date()],
          includeDisabled: true,
        },
      },
      hours: [
        {
          id: 1,
          hour: {
            to: "2000-01-01T09:00:00.000Z",
            from: "2000-01-01T12:30:00.000Z",
          },
        },
        {
          id: 2,
          hour: {
            to: "2000-01-01T09:00:00.000Z",
            from: "2000-01-01T12:30:00.000Z",
          },
        },
        {
          id: 3,
          hour: {
            to: "2000-01-01T09:00:00.000Z",
            from: "2000-01-01T12:30:00.000Z",
          },
        },
      ],
      reservation: { date: new Date() },
      allCountries: [
        ["Afghanistan (‫افغانستان‬‎)", "af", "93"],
        ["Albania (Shqipëri)", "al", "355"],
        ["Algeria (‫الجزائر‬‎)", "dz", "213"],
        ["American Samoa", "as", "1684"],
        ["Andorra", "ad", "376"],
        ["Angola", "ao", "244"],
        ["Anguilla", "ai", "1264"],
        ["Antigua and Barbuda", "ag", "1268"],
        ["Argentina", "ar", "54"],
        ["Armenia (Հայաստան)", "am", "374"],
        ["Aruba", "aw", "297"],
        ["Australia", "au", "61", 0],
        ["Austria (Österreich)", "at", "43"],
        ["Azerbaijan (Azərbaycan)", "az", "994"],
        ["Bahamas", "bs", "1242"],
        ["Bahrain (‫البحرين‬‎)", "bh", "973"],
        ["Bangladesh (বাংলাদেশ)", "bd", "880"],
        ["Barbados", "bb", "1246"],
        ["Belarus (Беларусь)", "by", "375"],
        ["Belgium (België)", "be", "32"],
        ["Belize", "bz", "501"],
        ["Benin (Bénin)", "bj", "229"],
        ["Bermuda", "bm", "1441"],
        ["Bhutan (འབྲུག)", "bt", "975"],
        ["Bolivia", "bo", "591"],
        ["Bosnia and Herzegovina (Босна и Херцеговина)", "ba", "387"],
        ["Botswana", "bw", "267"],
        ["Brazil (Brasil)", "br", "55"],
        ["British Indian Ocean Territory", "io", "246"],
        ["British Virgin Islands", "vg", "1284"],
        ["Brunei", "bn", "673"],
        ["Bulgaria (България)", "bg", "359"],
        ["Burkina Faso", "bf", "226"],
        ["Burundi (Uburundi)", "bi", "257"],
        ["Cambodia (កម្ពុជា)", "kh", "855"],
        ["Cameroon (Cameroun)", "cm", "237"],
        [
          "Canada",
          "ca",
          "1",
          1,
          [
            "204",
            "226",
            "236",
            "249",
            "250",
            "289",
            "306",
            "343",
            "365",
            "387",
            "403",
            "416",
            "418",
            "431",
            "437",
            "438",
            "450",
            "506",
            "514",
            "519",
            "548",
            "579",
            "581",
            "587",
            "604",
            "613",
            "639",
            "647",
            "672",
            "705",
            "709",
            "742",
            "778",
            "780",
            "782",
            "807",
            "819",
            "825",
            "867",
            "873",
            "902",
            "905",
          ],
        ],
        ["Cape Verde (Kabu Verdi)", "cv", "238"],
        ["Caribbean Netherlands", "bq", "599", 1],
        ["Cayman Islands", "ky", "1345"],
        ["Central African Republic (République centrafricaine)", "cf", "236"],
        ["Chad (Tchad)", "td", "235"],
        ["Chile", "cl", "56"],
        ["China (中国)", "cn", "86"],
        ["Christmas Island", "cx", "61", 2],
        ["Cocos (Keeling) Islands", "cc", "61", 1],
        ["Colombia", "co", "57"],
        ["Comoros (‫جزر القمر‬‎)", "km", "269"],
        ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "cd", "243"],
        ["Congo (Republic) (Congo-Brazzaville)", "cg", "242"],
        ["Cook Islands", "ck", "682"],
        ["Costa Rica", "cr", "506"],
        ["Côte d’Ivoire", "ci", "225"],
        ["Croatia (Hrvatska)", "hr", "385"],
        ["Cuba", "cu", "53"],
        ["Curaçao", "cw", "599", 0],
        ["Cyprus (Κύπρος)", "cy", "357"],
        ["Czech Republic (Česká republika)", "cz", "420"],
        ["Denmark (Danmark)", "dk", "45"],
        ["Djibouti", "dj", "253"],
        ["Dominica", "dm", "1767"],
        [
          "Dominican Republic (República Dominicana)",
          "do",
          "1",
          2,
          ["809", "829", "849"],
        ],
        ["Ecuador", "ec", "593"],
        ["Egypt (‫مصر‬‎)", "eg", "20"],
        ["El Salvador", "sv", "503"],
        ["Equatorial Guinea (Guinea Ecuatorial)", "gq", "240"],
        ["Eritrea", "er", "291"],
        ["Estonia (Eesti)", "ee", "372"],
        ["Ethiopia", "et", "251"],
        ["Falkland Islands (Islas Malvinas)", "fk", "500"],
        ["Faroe Islands (Føroyar)", "fo", "298"],
        ["Fiji", "fj", "679"],
        ["Finland (Suomi)", "fi", "358", 0],
        ["France", "fr", "33"],
        ["French Guiana (Guyane française)", "gf", "594"],
        ["French Polynesia (Polynésie française)", "pf", "689"],
        ["Gabon", "ga", "241"],
        ["Gambia", "gm", "220"],
        ["Georgia (საქართველო)", "ge", "995"],
        ["Germany (Deutschland)", "de", "49"],
        ["Ghana (Gaana)", "gh", "233"],
        ["Gibraltar", "gi", "350"],
        ["Greece (Ελλάδα)", "gr", "30"],
        ["Greenland (Kalaallit Nunaat)", "gl", "299"],
        ["Grenada", "gd", "1473"],
        ["Guadeloupe", "gp", "590", 0],
        ["Guam", "gu", "1671"],
        ["Guatemala", "gt", "502"],
        ["Guernsey", "gg", "44", 1],
        ["Guinea (Guinée)", "gn", "224"],
        ["Guinea-Bissau (Guiné Bissau)", "gw", "245"],
        ["Guyana", "gy", "592"],
        ["Haiti", "ht", "509"],
        ["Honduras", "hn", "504"],
        ["Hong Kong (香港)", "hk", "852"],
        ["Hungary (Magyarország)", "hu", "36"],
        ["Iceland (Ísland)", "is", "354"],
        ["India (भारत)", "in", "91"],
        ["Indonesia", "id", "62"],
        ["Iran (‫ایران‬‎)", "ir", "98"],
        ["Iraq (‫العراق‬‎)", "iq", "964"],
        ["Ireland", "ie", "353"],
        ["Isle of Man", "im", "44", 2],
        ["Israel (‫ישראל‬‎)", "il", "972"],
        ["Italy (Italia)", "it", "39", 0],
        ["Jamaica", "jm", "1876"],
        ["Japan (日本)", "jp", "81"],
        ["Jersey", "je", "44", 3],
        ["Jordan (‫الأردن‬‎)", "jo", "962"],
        ["Kazakhstan (Казахстан)", "kz", "7", 1],
        ["Kenya", "ke", "254"],
        ["Kiribati", "ki", "686"],
        ["Kosovo", "xk", "383"],
        ["Kuwait (‫الكويت‬‎)", "kw", "965"],
        ["Kyrgyzstan (Кыргызстан)", "kg", "996"],
        ["Laos (ລາວ)", "la", "856"],
        ["Latvia (Latvija)", "lv", "371"],
        ["Lebanon (‫لبنان‬‎)", "lb", "961"],
        ["Lesotho", "ls", "266"],
        ["Liberia", "lr", "231"],
        ["Libya (‫ليبيا‬‎)", "ly", "218"],
        ["Liechtenstein", "li", "423"],
        ["Lithuania (Lietuva)", "lt", "370"],
        ["Luxembourg", "lu", "352"],
        ["Macau (澳門)", "mo", "853"],
        ["Macedonia (FYROM) (Македонија)", "mk", "389"],
        ["Madagascar (Madagasikara)", "mg", "261"],
        ["Malawi", "mw", "265"],
        ["Malaysia", "my", "60"],
        ["Maldives", "mv", "960"],
        ["Mali", "ml", "223"],
        ["Malta", "mt", "356"],
        ["Marshall Islands", "mh", "692"],
        ["Martinique", "mq", "596"],
        ["Mauritania (‫موريتانيا‬‎)", "mr", "222"],
        ["Mauritius (Moris)", "mu", "230"],
        ["Mayotte", "yt", "262", 1],
        ["Mexico (México)", "mx", "52"],
        ["Micronesia", "fm", "691"],
        ["Moldova (Republica Moldova)", "md", "373"],
        ["Monaco", "mc", "377"],
        ["Mongolia (Монгол)", "mn", "976"],
        ["Montenegro (Crna Gora)", "me", "382"],
        ["Montserrat", "ms", "1664"],
        ["Morocco (‫المغرب‬‎)", "ma", "212", 0],
        ["Mozambique (Moçambique)", "mz", "258"],
        ["Myanmar (Burma) (မြန်မာ)", "mm", "95"],
        ["Namibia (Namibië)", "na", "264"],
        ["Nauru", "nr", "674"],
        ["Nepal (नेपाल)", "np", "977"],
        ["Netherlands (Nederland)", "nl", "31"],
        ["New Caledonia (Nouvelle-Calédonie)", "nc", "687"],
        ["New Zealand", "nz", "64"],
        ["Nicaragua", "ni", "505"],
        ["Niger (Nijar)", "ne", "227"],
        ["Nigeria", "ng", "234"],
        ["Niue", "nu", "683"],
        ["Norfolk Island", "nf", "672"],
        ["North Korea (조선 민주주의 인민 공화국)", "kp", "850"],
        ["Northern Mariana Islands", "mp", "1670"],
        ["Norway (Norge)", "no", "47", 0],
        ["Oman (‫عُمان‬‎)", "om", "968"],
        ["Pakistan (‫پاکستان‬‎)", "pk", "92"],
        ["Palau", "pw", "680"],
        ["Palestine (‫فلسطين‬‎)", "ps", "970"],
        ["Panama (Panamá)", "pa", "507"],
        ["Papua New Guinea", "pg", "675"],
        ["Paraguay", "py", "595"],
        ["Perú", "pe", "51"],
        ["Philippines", "ph", "63"],
        ["Poland (Polska)", "pl", "48"],
        ["Portugal", "pt", "351"],
        ["Puerto Rico", "pr", "1", 3, ["787", "939"]],
        ["Qatar (‫قطر‬‎)", "qa", "974"],
        ["Réunion (La Réunion)", "re", "262", 0],
        ["Romania (România)", "ro", "40"],
        ["Russia (Россия)", "ru", "7", 0],
        ["Rwanda", "rw", "250"],
        ["Saint Barthélemy", "bl", "590", 1],
        ["Saint Helena", "sh", "290"],
        ["Saint Kitts and Nevis", "kn", "1869"],
        ["Saint Lucia", "lc", "1758"],
        ["Saint Martin (Saint-Martin (partie française))", "mf", "590", 2],
        ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "pm", "508"],
        ["Saint Vincent and the Grenadines", "vc", "1784"],
        ["Samoa", "ws", "685"],
        ["San Marino", "sm", "378"],
        ["São Tomé and Príncipe (São Tomé e Príncipe)", "st", "239"],
        ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "sa", "966"],
        ["Senegal (Sénégal)", "sn", "221"],
        ["Serbia (Србија)", "rs", "381"],
        ["Seychelles", "sc", "248"],
        ["Sierra Leone", "sl", "232"],
        ["Singapore", "sg", "65"],
        ["Sint Maarten", "sx", "1721"],
        ["Slovakia (Slovensko)", "sk", "421"],
        ["Slovenia (Slovenija)", "si", "386"],
        ["Solomon Islands", "sb", "677"],
        ["Somalia (Soomaaliya)", "so", "252"],
        ["South Africa", "za", "27"],
        ["South Korea (대한민국)", "kr", "82"],
        ["South Sudan (‫جنوب السودان‬‎)", "ss", "211"],
        ["Spain (España)", "es", "34"],
        ["Sri Lanka (ශ්‍රී ලංකාව)", "lk", "94"],
        ["Sudan (‫السودان‬‎)", "sd", "249"],
        ["Suriname", "sr", "597"],
        ["Svalbard and Jan Mayen", "sj", "47", 1],
        ["Swaziland", "sz", "268"],
        ["Sweden (Sverige)", "se", "46"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Syria (‫سوريا‬‎)", "sy", "963"],
        ["Taiwan (台灣)", "tw", "886"],
        ["Tajikistan", "tj", "992"],
        ["Tanzania", "tz", "255"],
        ["Thailand (ไทย)", "th", "66"],
        ["Timor-Leste", "tl", "670"],
        ["Togo", "tg", "228"],
        ["Tokelau", "tk", "690"],
        ["Tonga", "to", "676"],
        ["Trinidad and Tobago", "tt", "1868"],
        ["Tunisia (‫تونس‬‎)", "tn", "216"],
        ["Turkey (Türkiye)", "tr", "90"],
        ["Turkmenistan", "tm", "993"],
        ["Turks and Caicos Islands", "tc", "1649"],
        ["Tuvalu", "tv", "688"],
        ["U.S. Virgin Islands", "vi", "1340"],
        ["Uganda", "ug", "256"],
        ["Ukraine (Україна)", "ua", "380"],
        ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "ae", "971"],
        ["United Kingdom", "gb", "44", 0],
        ["Estados Unidos", "us", "1", 0],
        ["Uruguay", "uy", "598"],
        ["Uzbekistan (Oʻzbekiston)", "uz", "998"],
        ["Vanuatu", "vu", "678"],
        ["Vatican City (Città del Vaticano)", "va", "39", 1],
        ["Venezuela", "ve", "58"],
        ["Vietnam (Việt Nam)", "vn", "84"],
        ["Wallis and Futuna (Wallis-et-Futuna)", "wf", "681"],
        ["Western Sahara (‫الصحراء الغربية‬‎)", "eh", "212", 1],
        ["Yemen (‫اليمن‬‎)", "ye", "967"],
        ["Zambia", "zm", "260"],
        ["Zimbabwe", "zw", "263"],
        ["Åland Islands", "ax", "358", 1],
      ],
      allCountriesParsed: null,
      filteredCode: null,
    };
  },
  computed: {},
  mounted() {
    this.allCountriesParsed = this.allCountries.map(function (country) {
      return {
        name: country[0],
        iso2: country[1].toUpperCase(),
        dialCode: country[2],
        priority: country[3] || 0,
        areaCodes: country[4] || null,
      };
    });
    this.filteredCode = this.allCountriesParsed.filter(
      (country) => this.country.split(",").indexOf(country.iso2) > -1
    );
    this.phoneCode = this.filteredCode[0].dialCode;
    this.$moment.locale("es");
    this.$root.$on("bv::modal::hidden", () => {
      this.showForm = false;
      this.footerText = "ACEPTAR";
      this.footerClass = "btn-info";
    });
    this.setStyle();
    this.set_hours(this.reservation.date);
  },
  created() {
    this.hours = [];
    if (this.customer_id) {
      this.set_days();
    }
    if (this.meeting_type) {
      this.setEventType();
    }
    if (this.is_button == false) {
      this.setElements();
    }
  },
  methods: {
    setStyle() {
      if (document.getElementById("btn-calendar-text")) {
        if (this.top) {
          this.style.top = this.top;
          document.getElementById("btn-calendar-text").style.top = "15px";
        } else if (this.bottom) {
          this.style.bottom = this.bottom;
          document.getElementById("btn-calendar-text").style.bottom = "15px";
        }
        if (this.right) {
          this.style.right = this.right;
          document.getElementById("btn-calendar-text").style.right = "55px";
        } else if (this.left) {
          this.style.left = this.left;
          document.getElementById("btn-calendar-text").style.left = "55px";
        }
      }
    },
    send() {
      let self = this;
      this.loading = true;
      this.$v.appoint.$touch();
      if (!this.$v.appoint.$invalid) {
        this.axios
          .post("/api/v1/calendars", {
            appointment: this.appoint,
            customer_id: this.customer_id,
          })
          .then(() => {
            let name = this.appoint.name;
            self.appoint = {
              rut: null,
              name: null,
              lastname: null,
              telphone: null,
              space_id: null,
              appointment_date: null,
              event_type: null,
              project: self.appoint.project,
              source: self.appoint.source,
            };
            self.phone = null;
            self.showModal = false;
            self.loading = false;
            self.reservation.date = new Date();
            self.set_hours(self.reservation.date);
            self.$swal({
              icon: "success",
              title: "Gracias " + name,
              text: "¡Nos contactaremos contigo lo más pronto posible!",
              timer: 7500,
            });
            self.closeModalEvent();
            self.$v.appoint.$reset();
          })
          .catch((error) => {
            console.log(error);
            self.showModal = false;
            self.loading = false;
            self.$swal({
              icon: "error",
              title: "Oops...",
              text: "Algo anda mal!",
            });
          });
      } else {
        self.loading = false;
      }
    },
    setElements() {
      this.showModal = true;
      this.hideCalendar = !this.hideCalendar;
      this.showForm = false;
      this.calendar_get_hours_loading = false;
    },
    setEventType() {
      this.showForm = false;
      this.showEventType = true;
      this.modalTitle = "¿Cómo preferirías agendar tu reunión?";
    },
    setForm() {
      this.modalTitle = "Confirma y completa tus datos";
      this.showForm = true;
    },
    goCalendarVC() {
      this.appoint.event_type = "VIDEO CONFERENCIA";
      this.modalTitle =
        "Selecciona una fecha y hora para agendar una videollamada con uno de nuestros ejecutivos";
      this.showForm = false;
      this.showEventType = false;
      this.showMessage = true;
    },
    goCalendarV() {
      this.appoint.event_type = "VISITA";
      this.modalTitle =
        "Selecciona una fecha y hora para visitar nuestra sala de ventas";
      this.showForm = false;
      this.showEventType = false;
      this.showMessage = true;
    },
    goBack() {
      if (this.appoint.event_type == "VIDEO CONFERENCIA") {
        this.modalTitle =
          "Selecciona una fecha y hora para agendar una videollamada con uno de nuestros ejecutivos";
      } else if (this.appoint.event_type == "VISITA") {
        this.modalTitle =
          "Selecciona una fecha y hora para visitar nuestra sala de ventas";
      } else {
        this.modalTitle =
          "Selecciona una fecha y hora para juntarte con uno de nuestros ejecutivos";
      }
      this.showForm = false;
      this.showEventType = false;
    },
    closeModalEvent() {
      if (this.meeting_type == true) this.showEventType = true;
    },
    set_days() {
      let self = this;
      this.axios
        .get("/api/v1/calendars", {
          params: {
            customer_id: this.customer_id,
            project_id: this.project_id,
          },
        })
        .then((result) => {
          if (result.data.mode) {
            this.parse_day_mode(result.data);
          } else {
            this.parse_day(result.data);
          }
          self.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parse_day(result) {
      let parsedDates = [];
      for (let unavaliable_date of result.dates) {
        parsedDates.push(new Date(unavaliable_date + " 12:00:00"));
      }
      this.dateState.disabled.dates = parsedDates;
    },
    parse_day_mode(result) {
      let parsedDates = [];
      for (let unavaliable_date of result.dates) {
        let date = unavaliable_date.split("-");
        parsedDates.push(new Date(date[0], date[1] - 1, date[2]));
      }
      let minDate = result.min.split("-");
      let maxDate = result.max.split("-");
      this.dateState.disabled = {
        to: new Date(minDate[0], minDate[1] - 1, minDate[2]), // Disable all dates up to specific date
        from: new Date(maxDate[0], maxDate[1] - 1, maxDate[2]), // Disable all dates after specific date
        dates: parsedDates,
      };
    },
    selectHour(hour) {
      this.appoint.work_hour_id = hour.id;
      this.selectedHour = hour.hour;
      this.setForm();
    },
    checkPhone() {
      if (this.phone) {
        if (this.phone.length >= 9) {
          var x = this.phone
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
          this.phone = !x[2]
            ? x[1]
            : "" + x[1] + "" + x[2] + (x[3] ? "" + x[3] : "");
          this.appoint.telphone = "+" + this.phoneCode + this.phone;
        } else {
          this.appoint.telphone = null;
        }
      }
    },
    set_hours(receivedDate) {
      this.hours = [];
      this.calendar_get_hours_loading = true;
      let date = moment(receivedDate).format("YYYY-MM-DD");
      this.axios
        .post("/api/v1/calendars/hours", {
          customer_id: this.customer_id,
          date: date,
          project_id: this.project_id,
        })
        .then((result) => {
          let available_hours = [];
          result.data.map((data) => {
            available_hours.push({
              id: data.id,
              seats: 3,
              hour: { to: data.name },
            });
          });
          this.appoint.appointment_date = date;
          this.calendar_get_hours_loading = false;
          this.hours = available_hours;
        });
    },
  },
  watch: {
    "reservation.date": {
      handler: function () {
        this.set_hours(this.reservation.date);
      },
    },
    showModal: {
      handler: function () {
        this.showForm = false;
        this.footerText = "ACEPTAR";
        this.footerClass = "btn-info";
        if (this.showModal == false) this.hideCalendar = true;
      },
    },
  },
};
</script>
